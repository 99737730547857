
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import { K12School, StudentProgress } from "@/models/interface";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
import CommonMixin from "@/mixins/common.mixin";
import SchoolMixin from "@/mixins/school.mixin";

@Component({
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
  metaInfo: {
    title: "About Page",
  },
})
export default class StudentProgressPage extends Mixins(
  ScrollToTopMixin,
  CommonMixin,
  SchoolMixin
) {
  school!: K12School;

  recordsForSchoolType = "";
  mobileView!: boolean;
  tabletView!: boolean;

  viewStudentProgressInfoSideBar = false;
  viewStudentProgressDetailsSideBar = false;

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  get yearFromEntity(){
    let result;
    if(this.schoolSystem)
    {
        result = this.overallPerformance.schoolSystemCurrentYear.letterGradeYearFormatted;
    }
      else{
        result = this.overallPerformance.schoolCurrentYear.letterGradeYearFormatted;
      }
    return result;
  }

  get yearRange(): string {
    return (
      +this.school.overallPerformance.currentYear -
      1 +
      "-" +
      this.school.overallPerformance.currentYear
    );
  }

  viewDetail(schoolType: string, sideBar: string) {
    switch (sideBar) {
      case "studentProgressInfo":
        this.recordsForSchoolType = '';
        this.viewStudentProgressInfoSideBar = true;
        this.viewStudentProgressDetailsSideBar = false;
        break;
      case "studentProgressDetails":
        this.viewStudentProgressInfoSideBar = false;
        this.viewStudentProgressDetailsSideBar = true;
        this.recordsForSchoolType = schoolType;
        break;
      default:
      this.recordsForSchoolType = '';
        this.viewStudentProgressInfoSideBar = false;
        this.viewStudentProgressDetailsSideBar = false;
        break;
    }
  }

  get studentProgress(): StudentProgress {
    if (this.recordsForSchoolType == "") {
      return new StudentProgress();
    } else {
      return (
        this.school.studentProgress.find(
          (sp) => sp.recordsForSchoolType == this.recordsForSchoolType
        ) || new StudentProgress()
      );
    }
  }
}
